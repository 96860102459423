import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';
import { listingFieldEnum } from '../../../config/configListingEnums';

import css from './SearchMapLogoLabel.module.css';

class SearchMapLogoLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSameLogo = this.getIconURL(currentListing) === this.getIconURL(nextListing);
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSameLogo && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      onListingClicked,
      isActive,
    } = this.props;
    const currentListing = ensureListing(listing);
    const currentlistingIconURL = this.getIconURL(currentListing);

    const classes = classNames(rootClassName || css.root, className);
    const logoLabelClasses = classNames(css.logoLabel, {
      [css.mapLabelActive]: isActive,
    });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        {/* <div className={css.caretShadow} /> */}
        <img src={currentlistingIconURL} className={logoLabelClasses}></img>
        {/* <div className={caretClasses} /> */}
      </button>
    );
  }

  getIconURL(currentListing) {
    const category = currentListing?.attributes?.publicData?.[listingFieldEnum.CATEGORY]?.[0];
    if (category) {
      return `/static/icons/categories/${category}.png`;
    }
    const variants = currentListing?.author?.profileImage?.attributes?.variants;
    const variant = variants?.['square-small'] || variants?.['scaled-small'];
    const currentlistingIconURL = variant?.url;
    return currentlistingIconURL;
  }
}

SearchMapLogoLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapLogoLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
};

export default SearchMapLogoLabel;
